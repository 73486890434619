// @flow

import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Analytics from '../../utils/analytics'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import PaddedContent from '../../components/PaddedContent'
import Nav from '../../components/Nav'
import { type FluidWithWebp } from '../../types'
import { Header } from '../../styles/pages/404'
// import Logo from '../../components/Logo'
// import { LogoContainer } from './style'

type Props = {
  location: any,
  data: {
    headingImage: {
      image: FluidWithWebp,
    },
  },
}

class FlightAttendantPortal extends React.Component<Props, *> {
  componentDidMount() {
    Analytics.pageview()
  }

  render() {
    const { location, data } = this.props

    return (
      <Layout>
        <SEO title="Not Found" location={location} />
        <Header>
          <Header.Background />
          <Nav location={location} />
          <PaddedContent>
            <Header.Title>Flight Attendant Portal</Header.Title>
            <div
              style={{
                position: 'absolute',
                left: '50%',
                top: '80%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <Img fixed={data.headingImage.image.fixed} alt="" />
            </div>
          </PaddedContent>
        </Header>
      </Layout>
    )
  }
}

export default FlightAttendantPortal

export const query = graphql`
  query FlightAttendantPortalQuery {
    headingImage: file(relativePath: { eq: "styles/pages/assets/goji-logo.png" }) {
      image: childImageSharp {
        fixed(width: 178, height: 96) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
